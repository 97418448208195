import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { observer } from 'mobx-react'
import { useParams } from "react-router-dom";

import './css/book.css';

import Book_Home from './home.js';
import Book_Dash from './dash.js';
import Book_Closed from './closed.js';
import Book_Step from './step/step.js';

import * as net from "../lib/net";
import Tiden from "../lib/ui/tiden";
import {withRouter} from "../lib/react/withRouter";
import sbo from './sbo.js'

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

function web01() {

    var html =
    <div className="web-content">
    </div>
    ;
    return html;
}

class Book extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;

    this.state = {};
    this.state.uid = "";
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var bcs = {};
    bcs.kro = {};
    bcs.vgrps = [];
    this.props.store.bcs = bcs;
    //alert(JSON.stringify(pos));
    //this.bcs_init();

    let { id } = this.props.params;
    sbo.cfg.uid = id;
    sbo.cfg.logo = "logo-dorsia.png"
    //alert(JSON.stringify(id));
}

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.navigate(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }


  render() {
    if(sbo.cfg.uid !== "dorsia") {
    }
  
    return (
    <div className="web-app">

{
  sbo.cfg.uid !== "dorsia" ?          
  <Routes>
  <Route path="*" element={ <Book_Closed store={this.store} />} />
  </Routes>
  :
  <Routes>
  <Route path="step/*" element={ <Book_Step store={this.store} />} />
  <Route path="sel/*" element={ <Book_Home store={this.store} />} />
  <Route path="home" element={ <Book_Home store={this.store} />} />
  <Route path="*" element={ <Book_Step store={this.store} />} />
  </Routes>
}

    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(withParams(observer(Book)));
