import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import {withRouter} from "../../lib/react/withRouter";
import sbo from '../sbo.js'
import Fr_Input from "../../lib/ui/fr_input";
import Fr_Inpsel from "../../lib/ui/fr_inpsel";

const products = [
  {
    id: 1,
    title: 'Basic Tee',
    href: '#',
    price: '$32.00',
    color: 'Black',
    size: 'Large',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/checkout-page-02-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
  },
  // More products...
]
const deliveryMethods = [
  { id: 1, title: 'Standard', turnaround: '4–10 business days', price: '$5.00' },
  { id: 2, title: 'Express', turnaround: '2–5 business days', price: '$16.00' },
]
const paymentMethods = [
  { id: 'credit-card', title: 'Credit card' },
  { id: 'paypal', title: 'PayPal' },
  { id: 'etransfer', title: 'eTransfer' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

class Rv_Book extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.rta = [];
    this.state.rta.push( { "rt": "SPQ", "text": "Small Premium", "pris": "240000", "desc": "Small Classic- 16 sqm- espresso machine- minibar- shower- free WiFi- ac" } );
    this.state.rta.push( { "rt": "SPC", "text": "Small Classic", "pris": "240000", "desc": "Small Classic- 16 sqm- espresso machine- minibar- shower- free WiFi- ac" } );
    this.state.rta.push( { "rt": "MCS", "text": "Medium Classic", "pris": "240000", "desc": "Small Classic- 16 sqm- espresso machine- minibar- shower- free WiFi- ac" } );
    this.state.rta.push( { "rt": "MPK", "text": "Medium Premium", "pris": "240000", "desc": "Small Classic- 16 sqm- espresso machine- minibar- shower- free WiFi- ac" } );
    this.state.deliverymethod = "";
    this.state.rvo = {};
    this.state.rvo.cdo = {};

    this.state.boknr = "";
    this.state.rvdone = 0;
 
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.book();
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 200;
      return css;
  }

  book() {
      var self = this;
    
      //alert(JSON.stringify(sbo.rvo));
      //alert(JSON.stringify(sbo.cdo));
      //return;

      if(sbo.rvo.done == 1) {
        alert("Bokning redan utförd, vänligne kontrollera inkorgen. Försök annars igen, tack!")
        return;
      }

      //var sid = "S0000091";
      //var sid = "S0001611";
      var sid = "ST000421";
      var boknr = "";
      var resno = "";
    
     var nyp00 = {};
     nyp00.n0bastyp = 'P';
     nyp00.n0sort = '10';
     nyp00.n0typ = 'F';
     nyp00.n0todatum  = '';
     nyp00.n0blank  = '';
     nyp00.n0text = 'Logi';
     nyp00.n0ejmoms = '0';
     nyp00.n0perpers = '0';
     nyp00.n0start = '1';
     nyp00.n0barntyp = '00';
     nyp00.n0cancelled = '';
     nyp00.n0paketpaket = '0';
     nyp00.n0fromdate = '';
     nyp00.n0minidagar = '';
     nyp00.n0minkrav = '';
     nyp00.n0psort = '';
     nyp00.n0filler0 = '';
     nyp00.n0sign = '';
     nyp00.n0updtime = '';
  
      var prm = {};
      prm.sid = sid;
      //prm.req = "pms.rv_op.rv_create";
      prm.req = "pms.rv.rvx_creat";
      prm.boknr = "";
      //alert(JSON.stringify(prm));
  
      //net.sio_req(prm)
      net.relay_req(prm)
      .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            alert("Bokning kan ej skapas");
              return;
        }
        boknr = ret.rco.boknr;
        //self.setState({boknr: boknr, rsv: rsv, resm: rsm });
        //self.setState({boknr: ret.rco.boknr, rvo: ret.rco.rvo });

        var pko = {};
        var pris = "";

        var brn = {};
        //this.state.brn.fdat = format(this.state.today, "yyyy-MM-dd");
        //this.state.brn.tdat = format(dat, "yyyy-MM-dd");
        brn.boknr = boknr;
        brn.fdat = sbo.rvo.fdat;
        brn.tdat = sbo.rvo.tdat;
        brn.nrr = "1";
        brn.nrg = sbo.rvo.nrg;
        brn.rt = sbo.rvo.rt;
        brn.pkat = "F";
        //alert(JSON.stringify(brn));

        var prm = {};
        prm.sid = sid;
        prm.req = "pms.br.br_add";
        prm.boknr = boknr;
        prm.rt = brn;
        prm.nyp00 = nyp00;
        prm.pris = pris;
        //alert(JSON.stringify(prm));
        //return;
        return net.relay_req(prm);
    })
    .then(function(ret){
      //alert(JSON.stringify(ret));

      if(ret.ok != "000") {
        alert("DelBokning kan ej skapas");
          return;
      }
      var rsv = {};
      rsv.resv00 = {};
      rsv.resv01 = {};
      rsv.resv02 = {};
      rsv.resv03 = {};
      rsv.resv04 = {};
      rsv.anm = {};

      rsv.resv00.resurs = "T";
      rsv.resv00.resno = boknr;
      rsv.resv00.Company = sbo.cdo.ftg;
      rsv.resv00.portal = "ecom";
      rsv.resv01.Name = sbo.cdo.namn;
      rsv.resv01.Tfn = sbo.cdo.tele;
      rsv.resv01.Mobil = sbo.cdo.mobil;
      rsv.resv02.Email = sbo.cdo.epost;
      rsv.resv02.anktid = "1800";
      rsv.resv02.bettyp = "";
      rsv.resv03.Adress1 = sbo.cdo.adress1;
      rsv.resv03.Adress2 = sbo.cdo.adress2;
      rsv.resv03.Adress3 = sbo.cdo.ort;
      rsv.resv04.Orgnr = "";
      rsv.resv04.Referens = "";
      rsv.resv04.Avdelning = "";
      rsv.resv04.Land = sbo.cdo.land;
      rsv.resv04.Sprak = "sv";
      rsv.resv04.Scb = "SE";
      rsv.resv04.Mkod = "";
      //alert(JSON.stringify(rsv));

      var resm = {};
      resm.resm00 = {};
      resm.resm01 = {};
      resm.resm02 = {};
      resm.resm03 = {};
      resm.resm04 = {};

      resm.resm00.resurs = "M";
      resm.resm00.resno = boknr;
      resm.resm00.Garanti = "";
      resm.resm00.Segment = "ecom";
      resm.resm01.Kanal = "ecom";
      resm.resm01.Extboknr = "";

      var prm = {};
      prm.sid = sid;
      prm.req = "pms.rv.rvx_upd";
      prm.boknr = boknr;
      prm.rsv = rsv;
      prm.resm = resm;
      //alert(JSON.stringify(prm));
      //alert(JSON.stringify(self.state.rsv));
    
      return net.relay_req(prm);
  })
  .then(function(ret){
    //alert(JSON.stringify(ret));

    if(ret.ok != "000") {
      alert("Bokning kan ej sparas");
      return;
}
  var prm = {};
  prm.sid = sid;
  prm.req = "pms.rv_op.rv_reserve";
  //prm.fkn = "7209";
  prm.boknr = boknr;
  prm.anm = "";
  //alert(JSON.stringify(prm));

  return net.relay_req(prm);
})
.then(function(ret){
    //alert(JSON.stringify(ret));
    if(ret.ok != "000") {
      alert("Bokning kan ej bokas");
      return;
    }
    sbo.rvo.resno = ret.resno;
    sbo.rvo.done = 1;
    resno = ret.resno;
    //self.setState({boknr: ret.resno, btn_rvtext: "SPARA" }, function(e) {
    //    self.rv_init();
    //});

    var subject = "Dorsia Hotel & Restaurant";
    var email = sbo.cdo.epost;
    
    var prm = {};
    prm.sid = sid;
    prm.req = "pms.bekr.mail_pms_confirm";
    prm.boknr = resno;
    prm.resno = resno;
    prm.subject = subject;
    prm.email = email;
    prm.lang = "SE";
    //alert(JSON.stringify(prm));

    net.relay_req(prm)
})
.then(function(ret){
  //alert(JSON.stringify(ret));
  self.setState({ boknr: resno, rvdone: 1 });
})
.catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }


  img_url(row) {
    var url = "https://fx.svepos.se/dorsia/smallclassic_P.jpg";
    return url;
  }
  cdo_save(x, y) {
  }

  step_to(url) {
  var self = this;
      sbo.unit.step = url;
      //setTimeout(function() {
      //    self.props.navigate(url);
      //}, 500);
    }

  render() {
    return (
    <div className="web-app">

      <div className="bg-dorsia flex">

      <div className="relative p-10 rounded-xl mx-auto mt-5 flex-center bg-white w-[1000px]">

      { this.state.rvdone != 1 ? 
        <p className="text-center">Bokning genomförs, vg dröj...</p>
      : null }

      { this.state.rvdone == 1 ? 
        <div style={{ fontSize: "32px" }}>
          <p className="text-center">Bokning klar</p>
          <p className="text-center">Bokningsnummer: { this.state.boknr }</p>
          <p className="text-center">Bekräftelse skickad</p>
          <p className="text-center">Tack!</p>
        </div>
      : null }

         <div className="flex flex-col">
         <button onClick={() => this.step_to("0") } className="m-5 bg-transparent hover:bg-gray-500 text-gray-700 hover:text-white py-4 px-12 border border-gray-500 hover:border-transparent rounded text-2xl">STÄNG</button>
        </div>

      </div>

      </div>  

    </div>
  );
    }
}
export default withRouter(observer(Rv_Book));


/*
         <button onClick={() => this.book() } className="m-5 bg-transparent hover:bg-gray-500 text-gray-700 hover:text-white py-4 px-12 border border-gray-500 hover:border-transparent rounded text-2xl">BOKA</button>

  rv_create() {
    var self = this;

    var prm = {};
    //prm.req = "pms.rv_op.rv_create";
    prm.req = "pms.rv.rvx_creat";
    prm.boknr = "";
    //alert(JSON.stringify(prm));

    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        if(ret.ok != "000") {
            alert("Bokning kan ej skapas");
              return;
        }
        var boknr = ret.rco.boknr;
        //var rsv = ret.rco.rsv;
        //var rsm = ret.rco.rsm;
        //var anm = ret.rco.anm;
        //rsv.anm = {};
        //self.setState({boknr: boknr, rsv: rsv, resm: rsm });
        //self.setState({boknr: ret.rco.boknr, rvo: ret.rco.rvo });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
    return;
  }

  brn_add(e) {
    var self = this;
    e.preventDefault();

    if(!this.state.brn.rt || (this.state.bro.rt == "")) {
        alert("Rumstyp saknas");
        return;
    }
    if(!this.state.brn.pkat || (this.state.bro.pkat == "")) {
        alert("Priskategori saknas");
        return;
    }
    var pko = this.pms.pko[this.state.brn.pkat];
    if(!pko) {
        alert("Priskategori objekt saknas");
        return;
    }
    var pris = "";
    //pris = "4990.00";

    var prm = {};
    prm.req = "pms.br.br_add";
    prm.boknr = this.state.boknr;
    prm.rt = this.state.brn;
    prm.nyp00 = pko;
    prm.pris = pris;
    //alert(JSON.stringify(prm.rt));
    //return;
    net.sio_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok == "000") {
                self.brs();
                return;
            }
        })
        .catch(function(e){
            var txt = JSON.stringify(e);
        });
}




rv_reserve() {
  var self = this;

  if(self.pms.rvo.bra.length < 1) {
      alert("Delbokning saknas");
      return;
  }
  if(self.state.rsv.resv01.Name == "") {
      alert("Namn saknas");
      return;
  }
  if(self.state.rsv.resv01.Tfn == "") {
      alert("Telefon saknas");
      return;
  }

  let pre = self.state.boknr.substr(0, 2);
  if(pre != pre.toLowerCase()) {
      self.rv_store();
      return;
  }

  var prm = {};
  prm.req = "pms.rv.rvx_upd";
  prm.boknr = self.state.boknr;
  prm.rsv = self.state.rsv;
  prm.resm = self.state.resm;
  //alert(JSON.stringify(prm));
  //alert(JSON.stringify(self.state.rsv));

  net.sio_req(prm)
  .then(function(ret){
      //alert(JSON.stringify(ret));

      if(ret.ok != "000") {
          throw "992 - Fel";
          return;
      }

      var prm = {};
      prm.req = "pms.rv.pmx_upd";
      prm.boknr = self.state.boknr;
      prm.anm = self.state.rsv.anm;
      return net.sio_req(prm);
  })
  .then(function(ret){
      //alert(JSON.stringify(ret));

      if(ret.ok != "000") {
          //self.toast("ERROR", "FEL Sparat");
          //return;
      }
      var prm = {};
      prm.req = "pms.rv_op.rv_reserve";
      //prm.fkn = "7209";
      prm.boknr = self.state.boknr;
      return net.sio_req(prm);
  })
  .then(function(ret){
      //alert(JSON.stringify(ret));

      if(ret.ok != "000") {
          return;
      }
       self.setState({boknr: ret.resno, btn_rvtext: "SPARA" }, function(e) {
           self.rv_init();
       });
  })
  .catch(function(e){
      var txt = JSON.stringify(e);
      self.toast("ERROR", "FEL: " + e);
  });

  return;
}
bekr_mail() {
  var self = this;

  var subject = this.state.subject;
  var email = this.state.email;
  if(email.trim() == "") {
      alert("Epost saknas");
      return;
  }
  if(subject.trim() == "") {
      subject = "Bokning: " + this.state.boknr;
  }
  var prm = {};
  prm.req = "pms.bekr.mail_pms_confirm";
  prm.boknr = this.state.boknr;
  prm.subject = subject;
  prm.email = email;
  prm.lang = this.state.lang;

  net.sio_req(prm)
  .then(function(ret){
      //alert(JSON.stringify(ret.rca));

      //self.setState({taa: ret.rca });
      self.toast("OK", "Bekräftelse skickad");
  })
  .catch(function(e) {
      var txt = JSON.stringify(e);
  });
}


*/