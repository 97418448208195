// Utils

export function toLang(s) {
      if(!s) s = "";
      s = s.toString().trim().toswe();
      return s;
};

export function toPris(s) {
      if(!s) s = "";
      s = s.toString().cdipris();
      return s;
};

export function toProc(s) {
      if(!s) s = "";
      s = s.toString().ztrim();
      s = s + "%";
      return s;
};
export function toMProc(s) {
      if(!s) s = "";
      s = s.toString().tonum(4);
      if(s == "0000") s = "0 %";
      if(s == "0566") s = "6 %";
      if(s == "0600") s = "6 %";
      if(s == "1071") s = "12 %";
      if(s == "1200") s = "12 %";
      if(s == "2000") s = "25 %";
      if(s == "2500") s = "25 %";
      return s;
};

export function toNum(val) {
      var ret = "";
      if(val) ret = val.toString().ztrim();
      return ret;
}

export function toDate(val) {
      var ret = "";
      if(val) ret = val.toString().cdidate();
      return ret;
}
export function toTsDate(val) {
      var input = val;
      if(!input) return "";
      if(input == undefined) return "";
      if( (input.length != 12) && (input.length != 14) ) return "";

      var datum = input.substr(0, 8);
      var tid = input.substr(8, 4);
      if(input.length == 14) tid = tid + input.substr(12, 2);

      if(datum.length == 8) datum = datum.substr(0, 4) + "-" + datum.substr(4, 2) + "-" + datum.substr(6, 2);
      if(tid.length == 4) tid = tid.substr(0, 2) + ":" + tid.substr(2, 2);
      if(tid.length == 6) tid = tid.substr(0, 2) + ":" + tid.substr(2, 2) + "." + tid.substr(4, 2);
      var output = datum + " " + tid;
      //alert(datum);
      return output;
};

export function toTid(val) {
      var ret = "";
      if(val) ret = val.toString().cditid();
      return ret;
}

export function toMinTid(val) {
      var ret = "";
      if(val) ret = val.toString().cdimin2tid();
      return ret;
}

export function toRumsnr(val) {
      var ret = "";
      if(val) ret = val.toString().cdirumsnr();
      return ret;
}
