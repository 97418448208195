import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'
import {addDays, differenceInCalendarDays, format, parseISO} from "date-fns";

import * as net from "../../lib/net";
import {withRouter} from "../../lib/react/withRouter";
import sbo from '../sbo.js'
import Fr_Input from "../../lib/ui/fr_input";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Step_Home extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.today = new Date();
    this.state.dts = format(this.state.today, "yyyyMMdd");
    //this.state.dts = parseISO(this.state.today);
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 200;
      return css;
  }

  trans() {
      var self = this;
    var datum = "20200328";
    var prm = {};
    prm.req = "pos.pos_cas.trs_dt";
    prm.fdat = datum;
    prm.tdat = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  rv_code(x, y) {
    var self = this;
    var rvcode = this.state.rvcode;
    rvcode = y;
    self.setState({rvcode: rvcode });
  }
  rv_real(url) {
    var self = this;
    var rvcode = this.state.rvcode;

    if(rvcode != "testboka") {
      alert("Felaktig kod, kod ej aktiv!");
      return;
    }
    if(true) {
      alert("System förbereds...");
      return;
    }

    sbo.rvo.fdat = self.state.dts;
    sbo.rvo.tdat = self.state.dts;
    sbo.rvo.nrg = "1";
    sbo.rvo.nrb = "0";

    sbo.unit.step = url;
  }
  step_to(url) {
    var self = this;
  
      sbo.rvo.fdat = this.state.dts;
      sbo.rvo.tdat = this.state.dts;
      sbo.rvo.nrg = "1";
      sbo.rvo.nrb = "0";

      sbo.unit.step = url;
      //setTimeout(function() {
      //    self.props.navigate(url);
      //}, 500);
    }

  render() {
    return (
    <div className="web-app">

      <div className="bg-dorsia flex">

      <div className="relative p-10 rounded-xl mx-auto mt-5 flex-center bg-white w-[1000px]">

        <h1 className="text-center text-2xl m-10">
          Dorsia Onlinebokning
        </h1>

        <p className="text-center">Test av direktbokning</p>
        <p className="text-center">Bokning sker i system kopia</p>
        <p className="text-center">Två olika földen finns ett enkelt och ett alternativ med bokning av flera rumstyper på samma bokning.</p>

        <div className="flex flex-col hide">
          <button onClick={() => this.step_to("1") } className="m-5 bg-transparent hover:bg-gray-500 text-gray-700 hover:text-green py-4 px-12 border border-gray-500 hover:border-transparent rounded text-2xl">Tryck för att starta test bokning</button>
        </div>

        <div className="divider_orange my-3"></div>
        <p className="text-center">Ange kod för skarp bokning</p>
        <form className="cdi_f03">
          <div className="flex-row flex-center">
            <Fr_Input label="" type="password" noflex noheader className="text-center w250" text={this.state.rvcode} getValue={(e)=> this.rv_code("rvcode", e) } />
            </div>
            </form>

            <div className="flex flex-col">
          <button onClick={() => this.rv_real("1") } className="m-5 bg-transparent hover:bg-gray-500 text-gray-700 hover:text-green py-4 px-12 border border-gray-500 hover:border-transparent rounded text-2xl">Testboka - Enkelt flöde</button>
        </div>
        <div className="flex flex-col">
          <button onClick={() => this.rv_real("1") } className="m-5 bg-transparent hover:bg-gray-500 text-gray-700 hover:text-green py-4 px-12 border border-gray-500 hover:border-transparent rounded text-2xl">Testboka - Avancerat flöde</button>
        </div>

      </div>

      </div>  

    </div>
  );
    }
}
export default withRouter(observer(Step_Home));
