import React, { useState, useEffect, useContext } from 'react';
import * as net from "../lib/net";
import {withRouter} from "../lib/react/withRouter";

import Book_Top from './com/book_top.js';
import Bcs_Mnu from './com/bcs_mnu.js';
import Book_Foot from './com/book_foot.js';

import './css/book.css';

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Book_Closed extends React.Component {
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.state = {};
    //context.posrend = this;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 200;
      return css;
  }
  rv_site() {
    var self = this;
    var url = "/dorsia/";
    setTimeout(function() {
        self.props.navigate(url);
    }, 500);
}
rv_cdi() {
  var self = this;
  var url = "/cdi/";
  setTimeout(function() {
      self.props.navigate(url);
  }, 500);
}


  render() {
    return (
    <div className="web-app">
    <div className="web-top">
        <Book_Top store={this.store} />
    </div>
    <div className="web-main">


    <div className="web-body">

    <div className="flex flex-col flex-cc">

      <div className="relative p-10 rounded-xl  bg-white max-w-2xl">

        <h1 className="text-center text-2xl m-10">
          STÄNGT
        </h1>

      </div>  
      </div>

      </div>

      </div>
          <Book_Foot store={this.store} />

    </div>
  );
    }
}
export default withRouter(Book_Closed);
