import React, { useState, useEffect, useContext } from 'react';
import {withRouter} from "../lib/react/withRouter";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import styled from "styled-components"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { observer } from 'mobx-react'

import * as net from "../lib/net";
import * as utils from "../assets/utils";
import "./css/login.css";

import sso from "../sso";
import ddo from "../ddo";
import axios from "axios";
import moment from "moment";
//import crypto from "crypto-js";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Web_Login extends React.Component {
    constructor(props, context) {
        super(props);

        this.store = props.store;
        this.state = {};
        this.state.seldate = new Date();

        this.state.paa = [];
        this.state.cdo = {};
        this.state.tot = {};
        this.state.signatur = "";

        this.state.signok = false;
        //this.state.signok = true;

        this.state.sz_val = "";
        this.state.showval = "";
        this.state.campaign = "";
        this.state.uid = "";
        this.state.pwd = "";

        this.state.cda = [];
        this.state.uso = {};
        this.state.wnds = {};
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        //this.syncTb("cdTable")
        //this.verify_ses();
    }
    verify_ses() {

        var xss = sessionStorage.getItem("cdi_sso");
        //alert(xdo);
        ddo.cfg = {};
        ddo.usr = {};
        if(xss) {
            var xso = JSON.parse(xss);
            //webStore.seo = seo;
            ddo.cfg = xso.cfg;
            ddo.usr = xso.usr;
            //return routefkn(sdo);
            this.setState({ signok: true, usr: ddo.usr, cco: {} });
        }
    }
    ses_logout() {
        sessionStorage.removeItem('cdi_sso');
        //sessionStorage.clear();
        ddo.cfg = {};
        ddo.usr = {};
        this.setState({ signok: false, usr: ddo.usr, cco: {} });
    }
    syslogo() {
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('./img/logo-cdi.png');
        //else logo = require('./img/logo-sk.png');
        logo = require('../img/logo-cdi.png');
        logo = require('../img/logo-spaider.png');
        return logo;
    }

    body_style() {
        var css = {};
        css.height = window.innerHeight - 280;
        return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 480;
        return css;
    }
    onUnitChange(e) {
        this.setState({unit: e.value});
    }

    body_style() {
        var css = {};
        css.height = window.innerHeight - 280;
        return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 250;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var srow = this.state.selrow;

        var css = {};

        return css;
    }
    handleUid (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({uid: value});
    }
    handlePwd (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({pwd: value});
    }

    login(){
        var self = this;
        var uid = this.state.uid;
        var pwd = this.state.pwd;

        pwd = pwd.trim();
        if(pwd.length < 1) {
          alert("Lösenord måste fyllas i");
          return;
        }

        var req = {};
        req.fkn = "login";
        req.uid = uid;
        req.pwd = pwd;

        //axios.post("http://127.0.0.1:9850/ses_login", req)
        axios.post("/ses_login", req)
        //net.ses_req(req)
        .then(function(rsp) {
            //alert("RSP: " + JSON.stringify(ret));
                //var cdo = res.data;

                //var key = "pLinK@2023";
                //var bytes = crypto.AES.decrypt(res.data, key);
                //var cds = bytes.toString(crypto.enc.Utf8);
                //var cdo = JSON.parse(cds);
                //alert("CDA: " + JSON.stringify(cda));

                //var rsp = JSON.parse(ret);
                //alert("RSP: " + JSON.stringify(rsp));
                if(rsp.ok != "000") {
                    alert("RSP: " + JSON.stringify(rsp));
                    return;
                }

                //var seo = {};
                var seo = rsp.seo;
                seo.verified = 1;
                var sesto = JSON.stringify( seo );
                //alert("SESTO: " + JSON.stringify(sesto));
                sessionStorage.setItem("spdr_sso", sesto);
                sso.seo.verified = 1;
                seo.verified = 1;
                //self.setState({ cdb: cda, cda: cda, mtot: tot, loading: 0 });
            })
            .catch((error) => {
                //alert("ERROR: " + JSON.stringify(error));
                //console.log(error.config);
            });
    }

    verify(){
        var self = this;
        var uid = this.state.uid;
        var pwd = this.state.pwd;

        pwd = pwd.trim();
        if(pwd.length < 1) {
          alert("Lösenord måste fyllas i");
          return;
        }

        var uso = {};
        uso.uid = uid;
        uso.pwd = pwd;

        var req = {};
        req.fkn = "INFO";
        req.token = self.state.token;
        req.uso = uso;
        //alert(JSON.stringify(req));

        var seo = {};
        seo.verified = 1;
        var sesto = JSON.stringify( seo );
        sessionStorage.setItem("spdr_sso", sesto);
        sso.seo.verified = 1;
    }

    cancel(){
        sessionStorage.removeItem("spdr_sso");
    }

    render() {
        return (
            <div className="web-app login_bg">
 
                <div className="flex-row flex-cc">
                    <h2>CLOUD SERVICES</h2>
                </div>
                <HR></HR>
               <div className="flex-col flex-cc flex">
                 <div className="cbbox">
                    <div className="imgcontainer">
                        <img src={ this.syslogo() } alt="Logo" className="logo" />
                    </div>

                    <div className="cdiform">
                        <div className="login-icon flex-col flex-cc">
                            <FontAwesomeIcon icon="fa-regular fa-user" size="1x" className="white" />
                        </div>

                        <div className="container flex-cc">
                            <label htmlFor="uname"><b>Username</b></label>
                            <input type="text" placeholder="Enter Username" name="uname" value={this.state.uid} onChange={(event) => this.handleUid(event)} required />

                            <label htmlFor="psw"><b>Password</b></label>
                            <input type="password" placeholder="Enter Password" name="psw" value={this.state.pwd} onChange={(event) => this.handlePwd(event)} required />

                            <div className="flex-row flex">
                            <button className="flex cancelbtn" onClick={() => this.cancel() }>Cancel</button>
                            <div className="flex"></div>
                            <button className="flex loginbtn" onClick={() => this.login() }>LOGGA IN</button>
                            </div>
                        </div>

                        <div className="container flex-row" style={{ backgroundColor: "#f1f1f1" }} >
                            <div className="flex"></div>
                            <div className="psw">Forgot <a href="#">password?</a></div>
                        </div>
                    </div>
                    </div>
            </div>

        </div>
        );
    }
}
export default withRouter(observer(Web_Login));

const HR = styled.div`
	margin: 0px;
	padding: 0px;
	border-top: 1px solid #880000;
`
