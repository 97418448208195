import React, {useState} from 'react';
import {observer} from "mobx-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as net from "../../lib/net";
import Dd_Sel from "../../lib/ui/dd_sel";
import {useNavigate} from "react-router-dom";
import sbo from '../sbo.js'

const steps = [
    { id: '01', name: 'Job details', href: '#', status: 'complete' },
    { id: '02', name: 'Application form', href: '#', status: 'current' },
    { id: '03', name: 'Preview', href: '#', status: 'upcoming' },
  ]
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  
function Book_Top(props) {
    var navigate = useNavigate();
    let [state, setState] = useState({wnds: {}, ma: []});

    //var seo = props.store.seo;

    var seo = {};
    var selitems = [
        {fkn: 'home', text: 'HEM'},
        {fkn: 'setup', text: 'INSTÄLLNINGAR'},
        {fkn: 'ses', text: 'MIN SESSION'},
    ];
     selitems.push( {fkn: 'logout', text: 'LOGGA UT'} );

    var it = {};

    var fa = "fa fa-key";
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpen = event => {
    setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const wnds_close = () => {
        setState({wnds: {}});
    };

    const goFull = () => {
        alert("Full");
    };

    function logout() {
        var url = "/logout";
        navigate(url);
    };
      function xlogout() {
        var self = this;
        var xdo = sessionStorage.getItem("svp");
		var sdo = JSON.parse(xdo);

		var prm = {};
        prm.req = "usr:logout";
		prm.uid = sdo.uid;
		prm.token = sdo.token;

		//alert(JSON.stringify(prm));
        net.gcs_req(prm)
        .then(function(ret){
            //alert(JSON.stringify(ret));

            if(ret.ok != "000") {
                alert(JSON.stringify(ret));
                //return;
            }
            setAnchorEl(null);
            sessionStorage.removeItem("svp");
            props.store.seo = {};

            var url = "/";
            navigate(url);
        })
        .catch(function(e){
            //var txt = JSON.stringify(e);
            alert("LOGOUT ERR: " + JSON.stringify(e));
            //gda.wndError(txt);
        });
      };
      const mnu_toggle = () => {
		//props.ctx.setState({ mnu_show: !props.ctx.state.mnu_show });
		props.store.cfg.mnu_show = !props.store.cfg.mnu_show;
      };
      const bread_toggle = () => {
		props.store.cfg.bread = !props.store.cfg.bread;
      };
      const mnu_item_css = () => {
      };
      const mnu_sel = () => {
      };
      const login = () => {
      };
      const header_class = () => {
  	    var cls = "flex_col";

		if(false) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";


		return cls;
    }
  function fknGo(url) {
    //alert(row.href);
      navigate(url);
  }
  function fkn_cs() {
      var wnds = {};
      wnds.ccs = true;
      wnds.backdrop = true;
      setState({"wnds": wnds});
  }
  function fkn_manager() {
      var wnds = {};
      wnds.manager = true;
      wnds.backdrop = true;
      setState({"wnds": wnds});
  }
    function sel_fkn(sel) {
        //alert(JSON.stringify(fkn));
        if(sel.fkn == "home") {
            var url = "/hello";
            navigate(url);
        }
      if(sel.fkn == "setup") {
          var wnds = {};
          wnds.setup = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "ses") {
          var wnds = {};
          wnds.ses = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "ccs") {
          var wnds = {};
          wnds.ccs = true;
          wnds.backdrop = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "man") {
          var wnds = {};
          wnds.manager = true;
          setState({"wnds": wnds});
      }
      if(sel.fkn == "logout") {
        var url = "/logout";
          navigate(url);
      }
  }
  function book_logo() {
    var logo = null;

    //var url = "../../img/";
    //url = url + sbo.cfg.logo;
    //logo = require(url);
    //logo = require("../../img/" + sbo.cfg.logo);
    //logo = require("https://fx.svepos.se/img/logo/" + sbo.cfg.logo);
    logo = "https://fx.svepos.se/img/logo/" + sbo.cfg.logo;
    //alert(logo);
    return logo;
}
function logo_css() {
    var css = {};
    css.width = "100%";
    css.maxHeight = "100%";
    if(sbo.cfg.logo_width) css.width = sbo.cfg.logo_width;
    return css;
}
function book_home() {
    var self = this;
    var url = "/" + sbo.cfg.uid + "/";
    setTimeout(function() {
        self.props.navigate(url);
    }, 500);
}
function head_css() {
    var css = {};
    css.margin = "auto";
    css.padding = "5px 20px";
    css.color = "#222";
    css.fontSize = "18px";
    return css;
}
function step_css(step) {
    var css = {};
    css.margin = "auto";
    css.padding = "5px 20px";
    css.color = "#999";
    css.cursor = "pointer";
    if(step < sbo.unit.step) css.color = "#080";
    if(step == sbo.unit.step) css.color = "#ff9900";
    return css;
}
function step_line(step) {
    var css = {};
    css.width = "50px";
    css.margin = "auto";
    css.borderBottom = "2px solid #222";
    css.borderColor = "#999";
    if(step < sbo.unit.step) css.borderColor = "#080";
    if(step == sbo.unit.step) css.borderColor = "#ff9900";
    return css;
}
function step_to(url) {
       sbo.unit.step = url;
      }
  
    var html =
    <div className="web-top flex">
    <div className="w-[1000px] flex-row mx-auto">

        <div className="" onClick={() => mnu_toggle() }>
            <img className="cursor-pointer h-[60px]" style={ logo_css() } src={ book_logo() } onClick={() => book_home() } />
        </div>

        <div className="flex"></div>
      { sbo.unit.step > "0" ?
        <div className="flex-row">
        <div style={ step_css("1") } onClick={() => step_to("1") }>Period</div>
        <div style={ step_line("2") } ></div>
        <div style={ step_css("2") } onClick={() => step_to("2") }>Rumsval</div>
        <div style={ step_line("3") }></div>
        <div style={ step_css("3") } onClick={() => step_to("3") }>Gästinformation</div>
        <div style={ step_line("4") }></div>
        <div style={ step_css("4") } onClick={() => step_to("4") }>Betalning</div>
        </div>
        : <div style={ head_css() }>Välkommen</div> }
        <div className="flex"></div>

        <div className="text-black web-top-icon" onClick={() => mnu_toggle() }>
            <FontAwesomeIcon icon="bars" size="1x" />
        </div>

        </div>
        </div>
    ;
    return html;
}

export default observer(Book_Top);

/*
*/