import React, { useState, useEffect, useContext } from 'react';
import {withRouter} from "../lib/react/withRouter";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import styled from "styled-components"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { observer } from 'mobx-react'

import * as utils from "../assets/utils";
import "./css/login.css";

import ddo from "../ddo";
import sso from "../sso";
import axios from "axios";
import moment from "moment";
//import crypto from "crypto-js";

function web01() {

    var html =
        <h4>Huvud</h4>
    ;
    return html;
}

class Web_Land extends React.Component {
    constructor(props, context) {
        super(props);

        this.store = props.store;
        this.state = {};
        this.state.seldate = new Date();
        this.state.paa = [];
        this.state.cdo = {};
        this.state.tot = {};
        this.state.signatur = "";

        this.state.signok = false;
        //this.state.signok = true;

        this.state.sz_val = "";
        this.state.showval = "";
        this.state.campaign = "";
        this.state.uid = "";
        this.state.pwd = "";

        this.state.rss = [];
        this.state.cda = [];
        this.state.uso = {};
        this.state.wnds = {};
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
    }
    componentDidMount() {
        //this.syncTb("cdTable")
        //this.verify_ses();
    }
    verify_ses(){
        var self = this;
        var seo = sso.sdo.seo;
        //alert("SEO: " + JSON.stringify(seo.sesid));
        var sesid = seo.sesid;

        var req = {};
        req.sesid = sesid;

        axios.post("http://127.0.0.1:9850/ses_verify", req)
        //axios.post("/ses_login", req)
            .then(res => {
                //alert("RSP: " + JSON.stringify(res));
                //var cdo = res.data;

                //var key = "pLinK@2023";
                //var bytes = crypto.AES.decrypt(res.data, key);
                //var cds = bytes.toString(crypto.enc.Utf8);
                //var cdo = JSON.parse(cds);
                //alert("CDA: " + JSON.stringify(cda));

                var rsp = JSON.parse(res.data);
                //alert("RSP: " + JSON.stringify(rsp));
                if(rsp.ok != "000") {
                    //alert("RSP: " + JSON.stringify(rsp));
                    sessionStorage.removeItem("spdr_sso");
                    sso.sdo.seo = {};
                    sso.seo.verified = 0;
                    return;
                }
                //self.setState({ cdb: cda, cda: cda, mtot: tot, loading: 0 });
            })
            .catch((error) => {
                //alert("ERROR: " + JSON.stringify(error));
                //console.log(error.config);
            });
    }

    xverify_ses() {

        var xss = sessionStorage.getItem("cdi_sso");
        //alert(xdo);
        ddo.cfg = {};
        ddo.usr = {};
        if(xss) {
            var xso = JSON.parse(xss);
            //webStore.seo = seo;
            ddo.cfg = xso.cfg;
            ddo.usr = xso.usr;
            //return routefkn(sdo);
            this.setState({ signok: true, usr: ddo.usr, cco: {} });
        }
    }
    ses_logout() {
        sessionStorage.removeItem('cdi_sso');
        //sessionStorage.clear();
        ddo.cfg = {};
        ddo.usr = {};
        this.setState({ signok: false, usr: ddo.usr, cco: {} });
    }
    syslogo() {
        var logo = null;
        //if(this.store.cfg.logo == "cdi") logo = require('./img/logo-cdi.png');
        //else logo = require('./img/logo-sk.png');
        logo = require('../img/logo-cdi.png');
        logo = require('../img/logo-spaider.png');
        logo = require('../img/logo-spaider-white.png');
        return logo;
    }

    body_style() {
        var css = {};
        css.height = window.innerHeight - 280;
        return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 480;
        return css;
    }
    onUnitChange(e) {
        this.setState({unit: e.value});
    }

    body_style() {
        var css = {};
        css.height = window.innerHeight - 280;
        return css;
    }
    tb_style() {
        var css = {};
        css.height = window.innerHeight - 250;
        css.overflow = "auto";
        return css;
    }
    th_style(id) {
        var css = {};
        css.cursor = "pointer";
        if(id == this.state.sortid) {
            if(this.state.sortdir) css.color = "#800";
            else css.color = "#080";
        }
        return css;
    }
    row_style(row) {
        var srow = this.state.selrow;

        var css = {};

        return css;
    }
    handleUid (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({uid: value});
    }
    handlePwd (e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({pwd: value});
    }

    verify(){
        var self = this;
        var uid = this.state.uid;
        var pwd = this.state.pwd;

        pwd = pwd.trim();
        if(pwd.length < 1) {
          alert("Lösenord måste fyllas i");
          return;
        }

        var uso = {};
        uso.uid = uid;
        uso.pwd = pwd;

        var req = {};
        req.fkn = "INFO";
        req.token = self.state.token;
        req.uso = uso;
        //alert(JSON.stringify(req));
    }

    fkngo(fkn){
        window.location.assign("https://www.w3schools.com")
    }
    cancel(){
    }
    xlogout(){
        sessionStorage.removeItem("spdr_sso");
        sso.seo.verified = 0;
    }
    logout(){
        var self = this;
        var seo = sso.sdo.seo;
        //alert("SEO: " + JSON.stringify(seo.sesid));
        var sesid = seo.sesid;

        var req = {};
        req.sesid = sesid;

        axios.post("http://127.0.0.1:9850/ses_logout", req)
        //axios.post("/ses_login", req)
            .then(res => {
                //alert("RSP: " + JSON.stringify(res));
                //var cdo = res.data;

                //var key = "pLinK@2023";
                //var bytes = crypto.AES.decrypt(res.data, key);
                //var cds = bytes.toString(crypto.enc.Utf8);
                //var cdo = JSON.parse(cds);
                //alert("CDA: " + JSON.stringify(cda));

                var rsp = JSON.parse(res.data);
                //alert("RSP: " + JSON.stringify(rsp));
                sessionStorage.removeItem("spdr_sso");
                sso.sdo.seo = {};
                sso.seo.verified = 0;
                //self.setState({ cdb: cda, cda: cda, mtot: tot, loading: 0 });
            })
            .catch((error) => {
                //alert("ERROR: " + JSON.stringify(error));
                //console.log(error.config);
            });
    }
    news(){
        var self = this;
        var seo = sso.sdo.seo;
        //alert("SEO: " + JSON.stringify(seo.sesid));
        var sesid = seo.sesid;

        var req = {};
        req.sesid = sesid;

        axios.get("http://127.0.0.1:9850/rss", req)
        //axios.post("/ses_login", req)
            .then(res => {
                //alert("RSP: " + JSON.stringify(res));
                //var cdo = res.data;

                //var rsp = JSON.parse(res.data);
                var rsp = res.data;
                //alert("RSP: " + JSON.stringify(rsp));
                 self.setState({ rss: rsp.rca, loading: 0 });
            })
            .catch((error) => {
                //alert("ERROR: " + JSON.stringify(error));
                //console.log(error.config);
            });
    }
    left_style() {
        var css = {};
        css.height = window.innerHeight - 75;
        css.overflow = "auto";
        return css;
    }
  
    render() {
        return (
            <div className="web-app pos_bg">
 
                <div className="flex-row">
                    <div className="">
                        <img src={ this.syslogo() } alt="Logo" className="logo_top" />
                    </div>
                    <div className="flex"></div>
                    <h2>CLOUD SERVICES</h2>
                    <div className="flex"></div>
                </div>
                <HR></HR>

            <div className="flex-row flex">

            <div className="flex-col pos_left" style={ this.left_style() }>
               <div className="txt_14" onClick={() => this.news() }>Nyheter</div>
               <HX></HX>
               { this.state.rss.map((row, key) =>
                    <div className="py-1">
                        <div className="txt_12">{ row.title }</div>
                        <HX></HX>
                    </div>
               )}
            </div>

            <div className="flex-col flex pos_body">

               <div className="flex-row">
               <div className="pos_box">
               <h3>Kund</h3>
                </div>
                <div className="pos_box">
                <h3>Anv</h3>
                </div>
                </div>
               <h3>Test</h3>

            </div>

            </div>

        </div>
        );
    }
}
export default withRouter(observer(Web_Land));

const HR = styled.div`
	margin: 0px;
	padding: 0px;
	border-top: 0px solid #880000;
`
const HX = styled.div`
	margin: 0px;
	padding: 0px;
	border-top: 1px solid #880000;
`
