import React, { useState, useEffect, useContext } from 'react';
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon, TrashIcon } from '@heroicons/react/20/solid'

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import {withRouter} from "../../lib/react/withRouter";
import sbo from '../sbo.js'
import Fr_Input from "../../lib/ui/fr_input";
import Fr_Inpsel from "../../lib/ui/fr_inpsel";

const products = [
  {
    id: 1,
    title: 'Basic Tee',
    href: '#',
    price: '$32.00',
    color: 'Black',
    size: 'Large',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/checkout-page-02-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
  },
  // More products...
]
const deliveryMethods = [
  { id: 1, title: 'Standard', turnaround: '4–10 business days', price: '$5.00' },
  { id: 2, title: 'Express', turnaround: '2–5 business days', price: '$16.00' },
]
const paymentMethods = [
  { id: 'credit-card', title: 'Credit card' },
  { id: 'paypal', title: 'PayPal' },
  { id: 'etransfer', title: 'eTransfer' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

class Step_S4 extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.rta = [];
    this.state.rta.push( { "rt": "SPQ", "text": "Small Premium", "pris": "240000", "desc": "Small Classic- 16 sqm- espresso machine- minibar- shower- free WiFi- ac" } );
    this.state.rta.push( { "rt": "SPC", "text": "Small Classic", "pris": "240000", "desc": "Small Classic- 16 sqm- espresso machine- minibar- shower- free WiFi- ac" } );
    this.state.rta.push( { "rt": "MCS", "text": "Medium Classic", "pris": "240000", "desc": "Small Classic- 16 sqm- espresso machine- minibar- shower- free WiFi- ac" } );
    this.state.rta.push( { "rt": "MPK", "text": "Medium Premium", "pris": "240000", "desc": "Small Classic- 16 sqm- espresso machine- minibar- shower- free WiFi- ac" } );
    this.state.deliverymethod = "";
    this.state.rvo = {};
    this.state.rvo.cdo = {};
 
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 200;
      return css;
  }

  trans() {
      var self = this;
    var datum = "20200328";
    var prm = {};
    prm.req = "pos.pos_cas.trs_dt";
    prm.fdat = datum;
    prm.tdat = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  img_url(row) {
    var url = "https://fx.svepos.se/dorsia/smallclassic_P.jpg";
    return url;
  }
  cdo_save(x, y) {
  }

  step_to(url) {
  var self = this;
      sbo.unit.step = url;
      //setTimeout(function() {
      //    self.props.navigate(url);
      //}, 500);
    }

  render() {
    return (
    <div className="web-app">

      <div className="bg-dorsia flex">

      <div className="relative p-10 rounded-xl mx-auto mt-5 flex-center bg-white w-[1000px]">

      <div className="flex-col flex">
          <p className="text-left text-xl">Betalning</p>
      </div>
      <div className="divider_orange my-3"></div>
      <p className="text-left text-xl">Kort</p>
      <form className="cdi_f03">
            <div className="flex-col" style={{border: "0px", margin: "0px", padding: "0px"}}>
                <div className="form-col flex-col flex">
                    <Fr_Input label="Kortinnehavare" className="text-left w250" text={this.state.rvo.cdo.cardholder} getValue={(e)=> this.cdo_save("cardholder", e) } />
                    <Fr_Input label="Kortnr" className="text-left w250" text={this.state.rvo.cdo.ccnr} getValue={(e)=> this.cdo_save("ccnr", e) } />
                    <Fr_Input label="Exp date" className="text-left w250" text={this.state.rvo.cdo.exp} getValue={(e)=> this.cdo_save("exp", e) } />
                    <Fr_Input label="CVV" className="text-left w250" text={this.state.rvo.cdo.cvv} getValue={(e)=> this.cdo_save("cvv", e) } />
                </div>
            </div>
        </form>

        <div className="divider_orange my-3"></div>

        <p className="text-left text-xl">Swish</p>
        <form className="cdi_f03">
            <div className="flex-col" style={{border: "0px", margin: "0px", padding: "0px"}}>
                <div className="form-col flex-col flex">
                    <Fr_Input label="Telefon nr" className="text-left w250" text={this.state.rvo.cdo.swishnr} getValue={(e)=> this.cdo_save("swishnr", e) } />
                </div>
            </div>
        </form>

        <div className="divider_orange my-3"></div>

        <div className="flex-col flex">
          <p className="text-left txt_b18">Policyer</p>
          <p className="text-left text-xl">Policy och villkorstexter mm</p>
          <p className="text-left text-sm">Avbokningsregler summeras även här</p>
        </div>
        <div className="divider_orange my-3"></div>

        <div className="flex-col flex">
          <p className="text-left txt_b18">Bekräftelser</p>
          <p className="text-left text-xl">Godkännanden och andra frågor</p>
        </div>

        <div className="divider_orange my-3"></div>

        <div className="flex flex-row justify-around">
        <button onClick={() => this.step_to("0") } className="bg-red-500 hover:bg-gray-500 hover:text-white text-gray-700 py-4 px-12 border border-gray-500 hover:border-transparent rounded text-2xl">Avbryt bokning</button>
        <button onClick={() => this.step_to("5") } className="bg-green-500 hover:bg-gray-500 hover:text-white text-gray-700 py-4 px-12 border border-gray-500 hover:border-transparent rounded text-2xl">Slutför bokning</button>
        </div>

         <div className="flex flex-col hide">
          <button onClick={() => this.step_to("1") } className="m-5 bg-transparent hover:bg-gray-500 text-gray-700 hover:text-white py-4 px-12 border border-gray-500 hover:border-transparent rounded text-2xl">NÄSTA</button>
        </div>

      </div>

      </div>  

    </div>
  );
    }
}
export default withRouter(Step_S4);
