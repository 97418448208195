import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import {withRouter} from "../../lib/react/withRouter";
import sbo from '../sbo.js'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Step_S2 extends React.Component {
  constructor(props, context) {
    super(props);

    this.webStore = props.store;
    this.state = {};
    this.state.rta = [];
    this.state.rta.push( { "rt": "SCS", "text": "Small Classic", "pris": "240000", "url": "smallclassic_P.jpg", "desc": "Small Classic- 16 sqm- espresso machine- minibar- shower- free WiFi- ac" } );
    this.state.rta.push( { "rt": "SPQ", "text": "Small Premium", "pris": "280000", "url": "smallpremium_P.jpg", "desc": "Small Premium- 16 sqm- espresso machine- minibar- shower- free WiFi- ac" } );
    this.state.rta.push( { "rt": "MCQ", "text": "Medium Classic", "pris": "320000", "url": "mediumclassic_P.jpg", "desc": "Medium Classic- 16 sqm- espresso machine- minibar- shower- free WiFi- ac" } );
    this.state.rta.push( { "rt": "MCK", "text": "Medium Premium", "pris": "420000", "url": "mediumpremium_P.jpg", "desc": "Medium Premium- 16 sqm- espresso machine- minibar- shower- free WiFi- ac" } );
    this.state.rta.push( { "rt": "LCK", "text": "Large Classic", "pris": "540000", "url": "largeclassic_P.jpg", "desc": "Large Classic- 16 sqm- espresso machine- minibar- shower- free WiFi- ac" } );
    this.state.rta.push( { "rt": "LPK", "text": "Large Premium", "pris": "640000", "url": "largepremium_P.jpg", "desc": "Large Premium- 16 sqm- espresso machine- minibar- shower- free WiFi- ac" } );
    this.state.rta.push( { "rt": "LEK", "text": "Large Exclusive", "pris": "760000", "url": "largeexclusive1_P.jpg", "desc": "Large Exclusive- 16 sqm- espresso machine- minibar- shower- free WiFi- ac" } );
    this.state.rta.push( { "rt": "LAR", "text": "Large Luxury", "pris": "860000", "url": "largeluxury_P.jpg", "desc": "Large Luxury- 16 sqm- espresso machine- minibar- shower- free WiFi- ac" } );
    

  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 200;
      return css;
  }

  trans() {
      var self = this;
    var datum = "20200328";
    var prm = {};
    prm.req = "pos.pos_cas.trs_dt";
    prm.fdat = datum;
    prm.tdat = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  img_url(row) {
    //var url = "https://fx.svepos.se/dorsia/smallclassic_P.jpg";
    var url = "https://fx.svepos.se/dorsia/" + row.url;
    return url;
  }

  rt_sel(row) {
  var self = this;

  var rt = row.rt + "-" + row.text;
  sbo.rvo.rt = rt;

  var url = "3";
  sbo.unit.step = url;
}

step_to(url) {
var self = this;
    sbo.unit.step = url;
      //setTimeout(function() {
      //    self.props.navigate(url);
      //}, 500);
    }
    gst_ch(e) {
      //alert(JSON.stringify(e));
      //var dts = format(e, "yyyy-MM-dd");
      //this.setState({ dts: dts });
    }
    addgst(e) {
      //alert(JSON.stringify(e));
      var nrg = sbo.rvo.nrg;
      nrg = ( nrg * 1 ) + 1;
      nrg = nrg.toString();
      sbo.rvo.nrg = nrg;
      this.setState({ nrg: nrg });
        }
        subgst(e) {
          //alert(JSON.stringify(e));
          var nrg = sbo.rvo.nrg;
          nrg = ( nrg * 1 ) - 1;
          if(nrg < 1) nrg = 1;
          nrg = nrg.toString();
          sbo.rvo.nrg = nrg;
          this.setState({ nrg: nrg });
      }
      addnrb(e) {
        //alert(JSON.stringify(e));
        var nrb = sbo.rvo.nrb;
        nrb = ( nrb * 1 ) + 1;
        nrb = nrb.toString();
        sbo.rvo.nrb = nrb;
        this.setState({ nrb: nrb });
          }
          subnrb(e) {
            //alert(JSON.stringify(e));
            var nrb = sbo.rvo.nrb;
            nrb = ( nrb * 1 ) - 1;
            if(nrb < 0) nrb = 0;
            nrb = nrb.toString();
            sbo.rvo.nrb = nrb;
            this.setState({ nrb: nrb });
        }
        nrgsel(e) {
          //alert(JSON.stringify(e));
          var nrg_sel = this.state.nrg_sel;
          nrg_sel = 1;
          this.setState({ nrg_sel: nrg_sel });
      }
      nrgclose(e) {
        e.preventDefault();
        var nrg_sel = this.state.nrg_sel;
        //alert(JSON.stringify(nrg_sel));
        nrg_sel = 0;
        this.setState({ nrg_sel: nrg_sel });
    }

  render() {
    return (
    <div className="web-app">

      <div className="bg-dorsia flex">

      <div className="relative p-10 rounded-xl mx-auto mt-5 flex-center bg-white w-[1000px]">

      <div className="flex-row py-2 justify-around">

      <div className="flex-col" onClick={() => this.step_to("1") }>
          <div className="text-left text-md">Ankomst - Avresa</div>
         <div className="text-left text-xl">{ utils.toDate(sbo.rvo.fdat) } - { utils.toDate(sbo.rvo.tdat) }</div>
      </div>

      <div className="flex-col" >
        <div className="text-left text-md" onClick={() => this.nrgsel() }>Gäster</div>
        <div className="text-left flex-row text-xl" onClick={() => this.nrgsel() }>
          <div>{ sbo.rvo.nrg } vuxna, { sbo.rvo.nrb } barn</div>
        </div>
      
      { this.state.nrg_sel == 1 ?
        <div className="flex-col flex-center">
          <div className="flex-row flex-center">
            <div className="dt-btn-01" onClick={() => this.subgst() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <input className="w200 text-center text-[16px] border-1" value={ sbo.rvo.nrg }  onChange={(e)=> this.gst_ch(e)} />
              <div className="dt-btn-01" onClick={() => this.addgst() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
          </div>
          <div className="flex-row flex-center">
            <div className="dt-btn-01" onClick={() => this.subnrb() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <input className="w200 text-center text-[16px] border-1" value={ sbo.rvo.nrb }  onChange={(e)=> this.gst_ch(e)} />
              <div className="dt-btn-01" onClick={() => this.addnrb() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
          </div>
          <div className="flex-row flex-center">
              <button className="btn btn-hw-green w200" onClick={(e) => this.nrgclose(e) } >Stäng</button>
          </div>
        </div>
      : null }

        </div>
       </div>
 
       <div className="divider_orange my-3"></div>

        { this.state.rta.map((row, key) =>
        <div key={ key } className="px-12 py-6 border-b-2 flex-col">

          <div className="flex-row flex">
          <div className="flex-col flex">
          <p className="text-left txt_b18">{ row.text }</p>
          <p className="text-left text-xl">{ row.rt }</p>
          <p className="text-left text-sm">{ row.desc }</p>
          </div>
          <div className="flex-col flex-4">
          <img style={{ width: "260px" }} src={ this.img_url( row ) } />
          </div>
          </div>

          <div className="flex-row flex">

          <div className="flex-col flex">
          <div className="divider_orange my-3"></div>
          <div className="flex-row flex">
          <div className="flex-col flex">
          <p className="text-left"><FontAwesomeIcon icon="fa-regular fa-bed" size="1x" /> Kingsize</p>
          <p className="text-left"><FontAwesomeIcon icon="fa-regular fa-vault" size="1x" /> Kassaskåp</p>
          <p className="text-left"><FontAwesomeIcon icon="fa-regular fa-wifi" size="1x" /> Wifi</p>
          </div>
          <div className="flex-col flex">
          <p className="text-left"><FontAwesomeIcon icon="fa-regular fa-lamp-desk" size="1x" /> Arbetsplats</p>
          <p className="text-left"><FontAwesomeIcon icon="fa-regular fa-mug-saucer" size="1x" /> Frukost</p>
          </div>
          </div>
          </div>

          <div className="flex-col flex py-2">
          <p className="text-right text-gray-900 text-xl">{ utils.toPris(row.pris) } per natt</p>
          <p className="text-right text-gray-500">Inklusive skatter och avgifter</p>
          <p className="text-right"><button onClick={() => this.rt_sel(row) } className="btn btn-hw-green w200">BOKA</button></p>
          </div>
          </div>

        </div>
        )}

        <div className="flex flex-col hide">
          <button onClick={() => this.step_to("3") } className="m-5 bg-transparent hover:bg-gray-500 text-gray-700 hover:text-white py-4 px-12 border border-gray-500 hover:border-transparent rounded text-2xl">NÄSTA</button>
        </div>

      </div>

      </div>  

    </div>
  );
    }
}
export default withRouter(observer(Step_S2));
