import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { configure } from "mobx"
import { observer } from 'mobx-react'

//import logo from './logo.svg';
import './css/rws.css';

import ddo from './ddo.js';
import sso from './sso.js';

import Login from './web/login.js';
import Logout from './web/logout.js';
import Land from './web/land.js';
import Book from './book/book.js';

import Store, { StoreProvider } from "./sdo";
import WebStore from "./ado";

const webStore = new WebStore();
const store = new Store();

function MainApp() {
    //var sdo = useContext(SDO);

    configure({
        enforceActions: "never",
    })

    const[txt, setTxt] = useState("SS00");
    var url = window.location.href;
    var tstUrl = url.match(/test.cdihotel.com/g);
    //var tstUrl = url.match(/localhost/g);
    //tstUrl = true;
    var hash = window.location.hash;
    if(hash) {
        //alert(JSON.stringify(hash));
    }

    var sesto = sessionStorage.getItem("spdr_sso");
     if(sesto) {
        //alert(JSON.stringify(sesto));
        var seo = JSON.parse(sesto);
        //alert(JSON.stringify(seo));
        //webStore.seo = seo;
        //sso.cfg = seo.cfg;
        //sso.seo = seo;
        //sso.sdo = seo.sdo;
        //return routefkn(sdo);

        sso.sdo.seo = seo;
        sso.sdo.sid = seo.sid;

        sso.seo.verified = seo.verified;
        sso.seo.sesid = seo.sesid;
        sso.seo.token = seo.sesid;

        sso.seo.name = seo.namn;
        sso.seo.company = seo.company;
        sso.seo.auth = seo.auth;
        //alert(JSON.stringify(seo));
    }

     if(sso.seo.verified !== 1) {
       var html =
        <BrowserRouter forceRefresh={false}>
            <Routes>
                <Route path="/bo" forceRefresh={true} element={ <Login store={webStore} />} />
                <Route path="/:id/*" element={ <Book store={webStore} />} />
               <Route path="*" forceRefresh={true} element={ <Book store={webStore} />} />
            </Routes>
        </BrowserRouter>
        return html;
    }

    webStore.cfg.brand = 'spdr';
    webStore.cfg.logo = 'spdr';
    //webStore.cfg.brand = 'svk';
    //webStore.cfg.logo = 'svk';
    //webStore.cfg.logo = 'cdi';
    webStore.cfg.mnu_show = 1;

    webStore.seo = {};
    webStore.seo.cs = 0;
    sso.seo.cs = 0;
    
    var pos = {};
    pos.deps = [];
    pos.vgrps = [];
    pos.plus = [];
    webStore.pos = pos;

    var pms = {};
    pms.rta = [];
    pms.rto = {};
    pms.pka = [];
    pms.pko = {};
    pms.stat = {};
    webStore.pms = pms;

    var ta = {};
    webStore.ta = ta;

    ddo.cache.avd = "01";

    var html =
    <StoreProvider store={store}>
        <BrowserRouter forceRefresh={false}>
        <Routes>
            <Route path="/land" forceRefresh={true} element={ <Land store={webStore} />} />
            <Route path="/logout" forceRefresh={true} element={ <Logout store={webStore} />} />
            <Route path="/sesout" forceRefresh={true} element={ <Land store={webStore} />} />

            <Route path="/:id/*" element={ <Book store={webStore} />} />
            <Route path="*" element={ <Book store={webStore} />} />
        </Routes>
        </BrowserRouter>
    </StoreProvider>
    ;
    return html;
}

export default observer(MainApp);
