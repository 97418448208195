import React, { useState, useEffect, useRef, useContext } from 'react';
import { format, getDate, getMonth, getWeek, eachDayOfInterval, eachWeekOfInterval } from 'date-fns'
import { setMonth, setYear, startOfWeek, endOfWeek, startOfMonth, endOfMonth, addMonths, subMonths, subDays, isSameDay } from 'date-fns'
import { sv } from 'date-fns/locale'

import './css/tiden.css';

class Tiden extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = {};
    this.state.wka = [];
    this.state.tid = {};
    //this.state.lro = props.lro;
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    this.setTime();
    setInterval(() => this.setTime(), 1000);
  }
    tid_style() {
      var css = {};
      //css.width = window.innerWidth * 0.8;
      //css.fontSize = "10px";
      if(window.innerWidth < 800) css.display = "none";
      return css;
    }

  handleClick = (e) => {
    if(!this.state.cal_show) return;

    if (!this.node.current.contains(e.target)) {
      this.setState({cal_show: false});
      return;
    }
  }
  setTime() {
        var dat = new Date();
       var tid = {};
        tid.datum = format(dat, "yyyy-MM-dd", { locale: sv });
        //tid.tidpkt = format(dat, "HH:mm", { locale: sv });
        tid.tidpkt = format(dat, "HH:mm.ss", { locale: sv });
        this.setState({tid: tid});
        //this.forceUpdate();
    }

  render() {
	var html =
    <div className="tid_ctrl flex-col" style={ this.tid_style() }>
        <span className="text-center">{ this.state.tid.datum }</span>
        <span className="text-center">{ this.state.tid.tidpkt }</span>
    </div>

	;
	return html;
  };
}

export default Tiden;