import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react'

import * as utils from "../../assets/utils";
import * as net from "../../lib/net";
import {withRouter} from "../../lib/react/withRouter";
import sbo from '../sbo.js'

import Dt_Cal from "../../lib/ui/dt_cal";
import Rv_Cal from "../../lib/ui/rv_cal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Step_S1 extends React.Component {
  constructor(props, context) {
    super(props);

    this.cal_node = React.createRef();

    this.webStore = props.store;

    this.state = {};
    this.state.today = new Date();
    //this.state.dts = parseISO(this.state.today);
    this.state.dts = "";
    this.state.nrg = "1";
    this.state.nrb = "0";
    this.state.nrg_sel = 0;
     
    this.state.rvo = {};
    this.state.rvo.fdat = "20240413";
    this.state.rvo.tdat = "20240414";
    
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
  }

  body_style() {
      var css = {};
      css.height = window.innerHeight - 280;
      return css;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 200;
      return css;
  }
  
  trans() {
      var self = this;
    var datum = "20200328";
    var prm = {};
    prm.req = "pos.pos_cas.trs_dt";
    prm.fdat = datum;
    prm.tdat = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
    }
    step_to(url) {
      var self = this;
      sbo.unit.step = url;
      //setTimeout(function() {
      //    self.props.navigate(url);
      //}, 500);
    }

    val_fd_save (dts) {
      var self = this;
      //alert("FD " + JSON.stringify(dts));
      var rvo = this.state.rvo;
      rvo.fdat = dts;
      sbo.rvo.fdat = dts;
      self.setState({ rvo: rvo });
    }
    val_td_save (dts) {
      var self = this;
      //alert("TD " + JSON.stringify(dts));
      var rvo = this.state.rvo;
      rvo.tdat = dts;
      sbo.rvo.tdat = dts;
      self.setState({ rvo: rvo });
    }
    gst_ch(e) {
      //alert(JSON.stringify(e));
      //var dts = format(e, "yyyy-MM-dd");
      //this.setState({ dts: dts });
    }
    addgst(e) {
      //alert(JSON.stringify(e));
      var nrg = sbo.rvo.nrg;
      nrg = ( nrg * 1 ) + 1;
      nrg = nrg.toString();
      sbo.rvo.nrg = nrg;
      this.setState({ nrg: nrg });
        }
        subgst(e) {
          //alert(JSON.stringify(e));
          var nrg = sbo.rvo.nrg;
          nrg = ( nrg * 1 ) - 1;
          if(nrg < 1) nrg = 1;
          nrg = nrg.toString();
          sbo.rvo.nrg = nrg;
          this.setState({ nrg: nrg });
      }
      addnrb(e) {
        //alert(JSON.stringify(e));
        var nrb = sbo.rvo.nrb;
        nrb = ( nrb * 1 ) + 1;
        nrb = nrb.toString();
        sbo.rvo.nrb = nrb;
        this.setState({ nrb: nrb });
          }
          subnrb(e) {
            //alert(JSON.stringify(e));
            var nrb = sbo.rvo.nrb;
            nrb = ( nrb * 1 ) - 1;
            if(nrb < 0) nrb = 0;
            nrb = nrb.toString();
            sbo.rvo.nrb = nrb;
            this.setState({ nrb: nrb });
        }
        nrgsel(e) {
          //alert(JSON.stringify(e));
          var nrg_sel = this.state.nrg_sel;
          nrg_sel = 1;
          this.setState({ nrg_sel: nrg_sel });
      }
      nrgclose(e) {
        e.preventDefault();
        var nrg_sel = this.state.nrg_sel;
        //alert(JSON.stringify(nrg_sel));
        nrg_sel = 0;
        this.setState({ nrg_sel: nrg_sel });
    }

    pms_status() {
      var self = this;
    
      //alert(JSON.stringify(sbo.rvo));
      //alert(JSON.stringify(sbo.cdo));
      //return;

      if(sbo.rvo.done == 1) {
        alert("Testläge. Försök igen, tack!")
        return;
      }

      var sid = "S0000091";
      //var sid = "S0001611";
      //var sid = "ST000421";
      var boknr = "";
      var resno = "";
      
      var prm = {};
      prm.sid = sid;
      prm.req = "pms.cal.pms_cal_per";
      prm.fdat = "20240425";
      prm.tdat = "20240425";
      //alert(JSON.stringify(prm));
  
      //net.sio_req(prm)
      net.relay_req(prm)
      .then(function(ret){
        //alert(JSON.stringify(ret.rca));

        var o = {};
        //o.deps = ret.rco;
        //alert(JSON.stringify(o));
        self.setState({tra: ret.rca });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
      
  render() {
    return (
    <div className="web-app">

    <div className="bg-dorsia flex">

      <div className="relative p-10 rounded-xl mx-auto mt-5 flex-center bg-white w-[1000px]">

      <div className="flex-row py-2 justify-around">

      <div className="flex-col">
          <div className="text-left text-md">Ankomst - Avresa</div>
         <div className="text-left text-xl">{ utils.toDate(sbo.rvo.fdat) } - { utils.toDate(sbo.rvo.tdat) }</div>
      </div>

      <div className="flex-col" >
        <div className="text-left text-md" onClick={() => this.nrgsel() }>Gäster</div>
        <div className="text-left flex-row text-xl" onClick={() => this.nrgsel() }>
          <div>{ sbo.rvo.nrg } vuxna, { sbo.rvo.nrb } barn</div>
        </div>
      
      { this.state.nrg_sel == 1 ?
        <div className="flex-col flex-center">
          <div className="flex-row flex-center">
            <div className="dt-btn-01" onClick={() => this.subgst() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <input className="w200 text-center text-[16px] border-1" value={ sbo.rvo.nrg }  onChange={(e)=> this.gst_ch(e)} />
              <div className="dt-btn-01" onClick={() => this.addgst() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
          </div>
          <div className="flex-row flex-center">
            <div className="dt-btn-01" onClick={() => this.subnrb() } ><FontAwesomeIcon icon="minus" size="1x" /></div>
              <input className="w200 text-center text-[16px] border-1" value={ sbo.rvo.nrb }  onChange={(e)=> this.gst_ch(e)} />
              <div className="dt-btn-01" onClick={() => this.addnrb() } ><FontAwesomeIcon icon="plus" size="1x" /></div>
          </div>
          <div className="flex-row flex-center">
              <button className="btn btn-hw-green w200" onClick={(e) => this.nrgclose(e) } >Stäng</button>
          </div>
        </div>
      : null }

        </div>
       </div>
 
       <div className="divider_orange my-3"></div>

        <div className="flex-row py-2 flex-center">
          <Rv_Cal label="Från" className="text-left w250" min="1" text={sbo.rvo.fdat} getFD={(e)=> this.val_fd_save( e ) } getTD={(e)=> this.val_td_save( e ) }/>
        </div>

        <div className="divider_orange my-3"></div>
        
        <div className="flex flex-row justify-around">
        <button onClick={() => this.step_to("0") } className="bg-red-500 hover:bg-gray-500 hover:text-white text-gray-700 py-4 px-12 border border-gray-500 hover:border-transparent rounded text-2xl">Avbryt</button>
        <button onClick={() => this.pms_status() } className="bg-red-500 hover:bg-gray-500 hover:text-white text-gray-700 py-4 px-12 border border-gray-500 hover:border-transparent rounded text-2xl">Test</button>
        <button onClick={() => this.step_to("2") } className="bg-green-500 hover:bg-gray-500 hover:text-white text-gray-700 py-4 px-12 border border-gray-500 hover:border-transparent rounded text-2xl">Sök ledigt</button>
        </div>

      </div>

      </div>  

    </div>
  );
    }
}
export default withRouter(observer(Step_S1));
