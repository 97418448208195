import React, { useState, useEffect, useContext } from 'react';
import { SDOContext } from '../sdo.js'
import { Chart } from 'chart.js'

//import './css/style-liberty.css';

import * as net from "../lib/net";

function web01() {

    var html =
    <div className="">
        Test
    </div>
    ;
    return html;
}

class Bcs_Dash extends React.Component {
    //var sdo = useContext(SDOContext); // Functional
    static contextType = SDOContext; // Class

    constructor(props, context) {
        super(props);

        this.s1ref = React.createRef();
        this.s2ref = React.createRef();

        this.my_bar = null;
        this.my_pie = null;
        this.my_stack = null;
        this.my_line = null;

        this.webStore = props.store;
        this.state = { pgid: 11, stat: [] };
        this.state.open = false;
        this.state.pdfopen = false;
        this.state.xxopen = false;
        this.state.pdf_uri = "";
        this.state.seldate = new Date('2020-03-24T13:11:54');
        this.state.row = null;
        this.state.info = "";
        this.state.top = 1;
        this.state.user = {};
        this.state.sdo = {};
        this.state.eka = [];
        this.state.hha = [];
        //this.state.sdo = context;
        //context.posrend = this;
        this.state.step = 1;
        this.state.pdf = 0;
        this.state.pdf_uri = "";
        this.state.mnus = ["m00", "m01", "m02", "m03", "m08", "m09"];
        this.state.mnu_sel = "m00";

        this.state.tot = {};
        this.state.tot.antal = "";
        this.state.tot.belopp = "";
        this.state.tot.brutto = "";
        this.state.tot.moms = "";
        this.state.tot.netto = "";
        this.state.tot.trs = "";
        this.state.tot.snitt_trs = "";
        this.state.tot.nrs = "";
        this.state.tot.snitt_nota = "";
        this.state.tot.bet = "";
        this.state.tot.kont = "";
        this.state.tot.rab = "";
        this.state.tot.diff = "";
        this.state.tot.resultat = "";

        this.chartColors = {
            navy: '#4755AB',
            green: '#03c895',
            danger: '#ff4f81',
            blue: '#4A496E',
            purple: '#B162AC',
            grey: '#E7EDF6'
        };
    }
    componentDidMount(){
        //var sdo = sessionStorage.getItem("svp");
        //if(sdo) this.setState({sdo: JSON.parse(sdo) });
        this.bar_chart();
        this.line_chart();
        //this.st_vgrp();
    }

    componentWillUnmount(){
    }
    onScroll(event) {
        var body = document.getElementById("web-body");
        if (body.scrollTop > 150) {
          this.setState({top: 0})
          this.setState({info: 'small'})
        } else {
          this.setState({top: 1})
          this.setState({info: 'big:'+body.scrollTop})
        }

        if (true) {
            var offset = body.scrollTop;
            var start = body.scrollTop;
            var slut = body.scrollTop + window.innerHeight;

            for(var mnuid of this.state.mnus) {
                const element = document.getElementById(mnuid);
                if(!element || (element == null) || (element == undefined)) continue;
                var eltop = element.offsetTop;
                var elbot = element.offsetTop + element.offsetHeight;
                var brc = eltop >= start && eltop <= slut;
                //if(!brc) brc = elbot >= start && elbot <= slut;
                if(brc) {
                    this.setState({mnu_sel: mnuid});
                    return;
                }
            }
        }
    }
   header_css() {
  	    var css = {};
		if(this.state.top) {
			css.color = "#fff";
			css.background = "transparent";
			css.height = "50px";
			//css["background"] = "green";
		}
		else {
			css.color = "#000";
			css.background = "#fff";
		    css.height = "50px";
        }

		return css;
    }
    header_class() {
  	    var cls = "flex_col";
		if(this.state.top) {
			cls = cls + " web-head_1";
		}
		else cls = cls +  " web-header";

		return cls;
    }
  st_vgrp() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_eko.tr_tot";
    prm.avd = this.state.avd;
    //prm.fplu = datum;
    //prm.tplu = datum;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var tot = ret.rco.tot;
        if(!tot) tot = {};
        if(!tot.belopp) tot.snitt_trs = "0";
        if(!tot.snitt_trs) tot.snitt_trs = "0";
        if(!tot.nrs) tot.nrs = "0";
        if(!tot.snitt_nota) tot.snitt_nota = "0";
        if(!tot.brutto) tot.brutto = "0";
        if(!tot.moms) tot.moms = "0";
        if(!tot.netto) tot.netto = "0";
        if(!tot.resultat) tot.resultat = "0";

        self.setState({ tot: tot, eka: ret.rca }, () => {
            self.bar_chart();
            self.st_hh();
        });
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
  st_hh() {
    var self = this;
    var prm = {};
    prm.req = "pos.pos_eko.tr_hh";
    prm.avd = this.state.avd;

    //gda.wndLoading("Hämtar");
    net.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        var dto = ret.dto;
        var a = [];
        var keys = Object.keys(dto.hhs);
        for(var key of keys) {
            var hho = dto.hhs[key];
            a.push( hho );
        }
        self.setState({hha: a }, () => self.line_chart());
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //gda.wndError(txt);
    });
  }
    bar_chart() {
      var lbls = [];
      var da = {};
      da.ink = [];
      da.ex = [];

      for(var row of this.state.eka) {
          if(row.grp == "tot") continue;
          lbls.push( row.text.toswe() );
          da.ink.push( row.belopp.cdipris() );
      }

        if(this.my_bar) this.my_bar.destroy();
        this.my_bar =  new Chart(document.getElementById("barchart"), {
            type: 'bar',
            data: {
				labels: lbls,
                datasets: [{
                    data: da.ink,
                    label: 'Belopp',
                    backgroundColor: "#4755AB",
                    borderWidth: 1,
                }]
            },
            options: {
                responsive: true,
                legend: {
                    display: false,
                    position: 'top'
                },
            }
        });
	};
    line_chart() {
      var lbls = [];
      var da = {};
      da.hh = [];
      da.nrof = [];

      for(var row of this.state.hha) {
          lbls.push( row.text.toswe() );
          var s2 = (row.belopp * 1) / (row.antal * 1);
          da.hh.push( row.belopp.cdipris() );
          da.nrof.push( s2.cdipris() );
      }

        if(this.my_line) this.my_line.destroy();
        this.my_line =  new Chart(document.getElementById("linechart"), {
            type: 'line',
            data: {
				labels: lbls,
                datasets: [{
                    label: 'Timfsg',
                    backgroundColor: this.chartColors.navy,
                    borderColor: this.chartColors.navy,
                    data: da.hh,
                    fill: false,
/*                }, {
                    label: 'Snitt per kvitto',
                    fill: false,
                    backgroundColor: this.chartColors.purple,
                    borderColor: this.chartColors.purple,
                    data: da.nrof,
*/                }]
            },
            options: {
                responsive: true,
                legend: {
                    display: false,
                    position: 'top'
                },
                // title: {
                // 	display: true,
                // 	text: 'Chart.js Line Chart'
                // },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                scales: {
                    x: {
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: 'Month'
                        }
                    },
                    y: {
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: 'Value'
                        }
                    }
                }
            }
        });
    };


    render() {
        var stat = [];
        var keys = Object.keys(this.state.stat);
        for (var key of keys) {
            var row = this.state.stat[key];
            stat.push(row);
        }
        const handleDateChange = date => {
            this.setState({seldate: date });
        };

        return (
            <div className="container-fluid" style={{background: "#eff1f9"}}>

                <div className="welcome-msg pt-3 pb-4">
                        <h1>Översikt</h1>
                </div>

                <div className="statistics">
                    <div className="row">
                        <div className="col-xl-6 pr-xl-2">
                            <div className="row">
                                <div className="col-sm-6 pr-sm-2 statistics-grid">
                                    <div className="card card_cdi p-4">
                                        <i className="lnr lnr-users"> </i>
                                        <h3 className="text-primary number">{ this.state.tot.brutto.cdipris() }</h3>
                                        <p className="stat-text">Ärenden</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 pl-sm-2 statistics-grid">
                                    <div className="card card_cdi p-4">
                                        <i className="lnr lnr-eye"> </i>
                                        <h3 className="text-secondary number">{ this.state.tot.moms.cdipris() }</h3>
                                        <p className="stat-text">Support</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 pl-xl-2">
                            <div className="row">
                                <div className="col-sm-6 pr-sm-2 statistics-grid">
                                    <div className="card card_cdi p-4">
                                        <i className="lnr lnr-cloud-download"> </i>
                                        <h3 className="text-success number">{ this.state.tot.netto.cdipris() }</h3>
                                        <p className="stat-text">Klara</p>
                                    </div>
                                </div>
                                <div className="col-sm-6 pl-sm-2 statistics-grid">
                                    <div className="card card_cdi p-4">
                                        <i className="lnr lnr-cart"> </i>
                                        <h3 className="text-danger number">{ this.state.tot.resultat.cdipris() } </h3>
                                        <p className="stat-text">Resultat</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="chart">
                    <div className="row">
                        <div className="col-lg-6 pr-lg-2 chart-grid">
                            <div className="card text-center card_cdi">
                                <div className="card-header chart-grid__header">
                                    Samtal
                                </div>
                                <div className="card-body">
                                    <div id="container">
                                        <canvas id="barchart"></canvas>
                                    </div>
                                </div>
                                <div className="card-footer text-muted chart-grid__footer">
                                    Detaljer och djupdykning
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-lg-2 chart-grid">
                            <div className="card text-center card_cdi">
                                <div className="card-header chart-grid__header">
                                    Timmar
                                </div>
                                <div className="card-body">
                                    <div id="container">
                                        <canvas id="linechart"></canvas>
                                    </div>
                                </div>
                                <div className="card-footer text-muted chart-grid__footer">
                                    Detaljer
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        );
    }

}
//WebPub.contextType = SDOContext; // Function
export default Bcs_Dash;
