import React, { useState, useEffect, useContext } from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { observer } from 'mobx-react'

import Step_Top from './step_top.js';
import Step_Foot from './step_foot.js';
import Step_Home from './home.js';
import Step_S1 from './s1.js';
import Step_S2 from './s2.js';
import Step_S3 from './s3.js';
import Step_S4 from './s4.js';
import Rv_Book from './rv_book.js';

import * as net from "../../lib/net";
import Tiden from "../../lib/ui/tiden";
import {withRouter} from "../../lib/react/withRouter";
import sbo from '../sbo.js'

class Book_Step extends React.Component {
    //var sdo = useContext(SDO);
  constructor(props, context) {
    super(props);

    this.store = props.store;
    this.pos = props.store.pos;
    this.state = {};
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {

    var bcs = {};
    bcs.kro = {};
    bcs.vgrps = [];
    this.props.store.bcs = bcs;
    //alert(JSON.stringify(pos));
    //this.bcs_init();
  }

  fknGo(url) {
    //var sdo = useContext(SDO);
    //alert(fkn);
    //var fkn = row.fid;
    //setState({ row : row,  ma : [] });
    //ctx.fid = fkn;

    //props.history.push(row.url);
    this.props.navigate(url);
   };

  ddo() {
    //alert("POS" + this.state.pgid );
    //this.setState({ status: 1 });
  }

    bcs_init() {
    var self = this;

	var req = {};
    req.req = "bcs.kreg.kks";
    req.typ = "K";

    //gda.wndLoading("Hämtar");
    net.sio_req(req)
    .then(function(ret){
        //alert(JSON.stringify(ret));

        //var pos = {};
        //pos.deps = ret.rco.deps;
        //self.props.store.pos = pos;
        if(!self.props.store.bcs) self.props.store.bcs = {};
        self.props.store.bcs.kro = ret.rco;
        //self.props.store.bcs.vgrps = ret.rco.vgrps;

        //self.setState({ deps: ret.rco });
        //toastr.success("OK", "SAPI");
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
        //alert(JSON.stringify(txt));
    })
    }

  render() {

    return (
    <div className="web-app">
    <div className="web-top">
        <Step_Top store={this.store} />
    </div>
    <div className="web-main">

    <div className="web-row" style={{ height:"100%" }}>

    <div className="web-body">

    { !sbo.unit.step ? <Step_Home store={this.store} /> : null }
    { sbo.unit.step == "" ? <Step_Home store={this.store} /> : null }
    { sbo.unit.step == "0" ? <Step_Home store={this.store} /> : null }
    { sbo.unit.step == "1" ? <Step_S1 store={this.store} /> : null }
    { sbo.unit.step == "2" ? <Step_S2 store={this.store} /> : null }
    { sbo.unit.step == "3" ? <Step_S3 store={this.store} /> : null }
    { sbo.unit.step == "4" ? <Step_S4 store={this.store} /> : null }
    { sbo.unit.step == "5" ? <Rv_Book store={this.store} /> : null }
    </div>

    </div>

    </div>
        <Step_Foot store={this.store} />
    </div>
  );
}
}
//Sys.contextType = ADOContext;

export default withRouter(observer(Book_Step));

/*
          <Routes>
              <Route path="s1" element={ <Step_S1 store={this.store} />} />
              <Route path="s2" element={ <Step_S2 store={this.store} />} />
              <Route path="home" element={ <Step_Home store={this.store} />} />
              <Route path="*" element={ <Step_Home store={this.store} />} />
          </Routes>

*/